import { useState, useEffect } from 'react';

import { CreateServiceReportComponentProperties, CreateServiceReportRequestModel } from '../models/ServiceReportModels';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { createServiceReport} from '../services/CreateServiceReportService';

interface DisplaySettings {
    showCreateServiceReportSpinner: boolean
    showCreateServiceReportException: boolean
    showCreateServiceReport: boolean

    showServiceReportPestDropdown: boolean
}

const CreateServiceReportComponent = ({ properties }: { properties: CreateServiceReportComponentProperties }) => {
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({} as DisplaySettings);
    const [serviceReportData, setServiceReportData] = useState<CreateServiceReportRequestModel>({ customerNumber: '', pm5Id: '', objectNumber: '', issueDescription: '', issueType: '', issueConcerns: '', contactName: '', contactEmail: '', contactPhone: '' });

    const resetServiceReportData = () => {

        setServiceReportData({
            customerNumber: properties.customnerNumber,
            pm5Id: properties.pm5Id,
            objectNumber: properties.objectNumber,
            issueDescription: '',
            issueType: 'Other',
            issueConcerns: 'Unknown', //'Apartment.All',
            contactName: properties.contactName,
            contactPhone: properties.contactPhone,
            contactEmail: properties.contactEmail
        });

        if (properties.showPestDropdown) {
            setServiceReportData((prevState) => ({ ...prevState, issueType: 'Pest.Ants', issueConcerns: 'Unknown' }))
        }

        setDisplaySettings((prevState) => ({ ...prevState, showServiceReportPestDropdown: properties.showPestDropdown }));
    }

    useEffect(() => {
        resetServiceReportData();
    }, [properties]); // eslint-disable-line react-hooks/exhaustive-deps

    const ShowSpinner = () => (
        <div className="spinner-border spinner-border-sm component-header-decoration float-end" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );

    const ShowPestDropdown = () => {

        return (<div className="pb-1">
            <label className="form-control-label">Skadedjur</label>
            <select className="form-control form-control-sm" disabled={displaySettings.showCreateServiceReportSpinner || displaySettings.showCreateServiceReport} value={serviceReportData.issueType} onChange={(e) => setServiceReportData((prevState) => ({ ...prevState, issueType: e.target.value }))}>
                <option value="Pest.Ants">Myror</option>
                <option value="Pest.Cockroaches">Kackerlackor</option>
                <option value="Pest.Rats">Råttor</option>
                <option value="Pest.Silverfish">Silverfiskar</option>
                <option value="Pest.BedBugs">Vägglöss</option>
                <option value="Pest.Other">Annat</option>
            </select>
        </div>)
    }
        

    const popoverServiceReport = () => {
        return (
            <Popover className="popover-size-4">
                <h3 className="popover-header">{properties.popoverLabel}</h3>

                <div className="position-absolute top-0 end-0 pt-1 pe-2">
                    <span className="fs-5" onClick={() => document.body.click()}><i className="fas fa-times"></i></span>
                </div>

                <div className="p-3 mb-2">
                    <div className="pb-1">
                        <span className="pb-2">{displaySettings.showServiceReportPestDropdown ? 'Välj skadedjur från listand nedan.' : '' }
                        Fyll i en beskrivning av ärendet nedan.</span><br />
                        Kontroller kontakt uppgifterna.
                    </div>
                    {displaySettings.showServiceReportPestDropdown ? <ShowPestDropdown /> : null }
                    <div>
                        <label className="form-control-label">Beskrivning</label>
                        <textarea className="w-100 form-control" rows={4} disabled={displaySettings.showCreateServiceReportSpinner || displaySettings.showCreateServiceReport} value={serviceReportData.issueDescription} onChange={(e) => setServiceReportData((prevState) => ({ ...prevState, issueDescription: e.target.value }))}></textarea>
                    </div>

                    <div>
                        <label className="form-control-label">Namn</label>
                        <input className="form-control form-control-sm" disabled={displaySettings.showCreateServiceReportSpinner || displaySettings.showCreateServiceReport} value={serviceReportData.contactName} onChange={(e) => setServiceReportData((prevState) => ({ ...prevState, contactName: e.target.value }))} />
                    </div>
                    <div>
                        <label className="form-control-label">Epost</label>
                        <input className="form-control form-control-sm" disabled={displaySettings.showCreateServiceReportSpinner || displaySettings.showCreateServiceReport} value={serviceReportData.contactEmail} onChange={(e) => setServiceReportData((prevState) => ({ ...prevState, contactEmail: e.target.value }))} />
                    </div>
                    <div>
                        <label className="form-control-label">Telefonnummer</label>
                        <input className="form-control form-control-sm" disabled={displaySettings.showCreateServiceReportSpinner || displaySettings.showCreateServiceReport} value={serviceReportData.contactPhone} onChange={(e) => setServiceReportData((prevState) => ({ ...prevState, contactPhone: e.target.value }))} />
                    </div>

                    <div className="w-100 text-end mt-2">
                        {displaySettings.showCreateServiceReportSpinner ? <ShowSpinner /> : null}
                        {displaySettings.showCreateServiceReportException ? <div className="text-danger mb-2">Något gick fel, testa att skicka igen.</div> : null}
                        {displaySettings.showCreateServiceReportSpinner || displaySettings.showCreateServiceReport ? null : <button className="btn btn-outline-primary btn-sm" onClick={() => tryCreateServiceReportPests()}><span className="me-2"><i className="fas fa-toolbox"></i></span>Skicka serviceärende </button>}
                        {displaySettings.showCreateServiceReport ? <div>Servicerapporten är skapad.</div> : null}
                    </div>
                </div>
            </Popover>
        )
    };

    const tryCreateServiceReportPests = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showCreateServiceReportSpinner: true, showCreateServiceReport: false }));

        createServiceReport(serviceReportData)
            .then((response) => {

                if (response) {
                    setDisplaySettings((prevState) => ({ ...prevState, showCreateServiceReport: true, showCreateServiceReportException: false }));
                }
                else {
                    setDisplaySettings((prevState) => ({ ...prevState, showCreateServiceReportException: true }));
                }
            })
            .finally(() => {
                setDisplaySettings((prevState) => ({ ...prevState, showCreateServiceReportSpinner: false }));
            });
    }

    const OnExitPopover = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showCreateServiceReport: false, showCreateServiceReportException: false }))
    };

    return (
        <div className="float-end ms-1 me-1">
            <OverlayTrigger trigger="click" rootClose placement="left" overlay={popoverServiceReport()} onExited={OnExitPopover}>
                <span className="clickable-icon"><i className={properties.iconClass}></i></span>
            </OverlayTrigger>
        </div>
    );
}
export default CreateServiceReportComponent;