import { useState } from 'react';
import { Customer } from '../models/CustomerModels';

import { RentObjectModel } from '../models/RentObjectModels';
import CustomerRentObjectResponsiblesContact from './CustomerRentObjectResponsiblesContactComponent';

const CustomerRentObjectResponsibles = ({ rentObject, customer }: { rentObject: RentObjectModel, customer: Customer }) => {


    const [showCompnent, setShowCompnent] = useState<Boolean>(false);

    if (!(rentObject.rentObjectProperty.careTaker || rentObject.rentObjectProperty.propertyDeveloper || rentObject.rentObjectProperty.propertyManager || rentObject.rentObjectProperty.rentalManager || rentObject.rentObjectProperty.socialManager || rentObject.rentObjectProperty.socialDeveloper)) {
        return (<div></div>)
    }
    else if (showCompnent) {

        return (
            <div>
                <div className="row">
                    <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(false)}>
                        <span>Ansvariga</span>
                        <span className="position-absolute btn-toggle-groups">
                            <i className="fa-solid fa-caret-down"></i>
                        </span>
                    </div>
                </div>
                <div className="row ms-1">
                    <CustomerRentObjectResponsiblesContact contacts={rentObject.rentObjectProperty.careTaker} customer={customer} label="Hyresvärd" rentObjectNumber={rentObject.number} />
                    <CustomerRentObjectResponsiblesContact contacts={rentObject.rentObjectProperty.propertyDeveloper} customer={customer} label="Lägenhets Utvecklare" rentObjectNumber={rentObject.number} />
                    <CustomerRentObjectResponsiblesContact contacts={rentObject.rentObjectProperty.propertyManager} customer={customer} label="Förvaltare" rentObjectNumber={rentObject.number}  />
                    <CustomerRentObjectResponsiblesContact contacts={rentObject.rentObjectProperty.rentalManager} customer={customer} label="Hyreshandläggare" rentObjectNumber={rentObject.number} />
                    <CustomerRentObjectResponsiblesContact contacts={rentObject.rentObjectProperty.socialManager} customer={customer} label="Bosociala Utvecklare" rentObjectNumber={rentObject.number} />
                    <CustomerRentObjectResponsiblesContact contacts={rentObject.rentObjectProperty.socialDeveloper} customer={customer} label="Bosocial Handläggare" rentObjectNumber={rentObject.number} />
                </div>
            </div>
        );
    }
    else {
        return (<div>
            <div className="row">
                <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(true)}>
                    <span>Ansvariga</span>
                    <span className="position-absolute btn-toggle-groups">
                        <i className="fa-solid fa-caret-left"></i>
                    </span>
                </div>
            </div>
        </div>);
    }
}
export default CustomerRentObjectResponsibles;