import { Customer } from '../models/CustomerModels';
import { ServiceObjectModel } from '../models/CustomerServiceReportModels';
import BaseClient from './BaseClient';

export async function getCustomerRentObjectServiceObjects({ id }: Customer): Promise<{ serviceObjects: ServiceObjectModel[], hasError: boolean }> {
    try {
        const data = await BaseClient.get('/customer/' + id + '/servicereport').then((response) => {
            if (response.data && response.data.length > 0) {
                return { serviceObjects: response.data, hasError: false };
            }
            else
            {
                return { serviceObjects: [] as ServiceObjectModel[], hasError: false };
            }
        });

        return data;
    } catch (error) {
        return { serviceObjects: [] as ServiceObjectModel[], hasError: true };
    }
}