import { useState } from 'react';

import { RentObjectModel } from '../models/RentObjectModels';

interface CustomerRentObjectRoomDetailProperies {
    rentObject: RentObjectModel
}

const CustomerRentObjectRoomDetailsComponent = ({ rentObject }: CustomerRentObjectRoomDetailProperies) => {

    const [showCompnent, setShowCompnent] = useState<Boolean>(false);

    if (!(rentObject.rentObjectProperty.propertyDetails) || rentObject.rentObjectProperty.propertyDetails.length === 0) {
        return (<div></div>)
    }
    else if (showCompnent) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor hover" onClick={() => setShowCompnent(false)}>
                        <span>Rumsbeskriving</span>
                        <span className="position-absolute btn-toggle-groups">
                            <i className="fa-solid fa-caret-down"></i>
                        </span>
                    </div>
                </div>
                <div className="row ms-1">
                    {
                        rentObject.rentObjectProperty.propertyDetails.map(ro => {
                            return (<div className="row" key={ro.room} >
                                <div className="col-5 text-start">{ro.room}</div>
                                <div className="col-7 text-start">{ro.details}</div>
                            </div>);
                        })
                    }
                </div>
            </div>
        );
    }
    else {
        return (<div>
            <div className="row">
                <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor hover" onClick={() => setShowCompnent(true)}>
                    <span>Rumsbeskriving</span>
                    <span className="position-absolute btn-toggle-groups">
                        <i className="fa-solid fa-caret-left"></i>
                    </span>
                </div>
            </div>
        </div>);
    }
}
export default CustomerRentObjectRoomDetailsComponent;