import { useState, useEffect } from 'react';

import { Customer } from '../models/CustomerModels'

import { RentObjectModel } from '../models/RentObjectModels'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getCustomerRentObjects } from '../services/CustomerRentObjectService'

import CustomerRentObjectComponent from './CustomerRentObjectComponent';


interface CustomerComponentProperties {
    customer: Customer
}

interface DisplaySettings {
    showRentObjectsLoadingSpiner: boolean
    showErrorInformation: boolean

    showSendReportPestsSpinner: boolean
    showSendReportPestsException: boolean
    showSentReportPests: boolean

    showSendReportPestDropdown: boolean
}

const CustomerRentObjectsComponent = ({ customer }: CustomerComponentProperties) => {
    const [rentObjects, setRentObjects] = useState<RentObjectModel[]>([] as RentObjectModel[]);
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({} as DisplaySettings);

    useEffect(() => {
        setRentObjects(customer.rentObjects);
    }, [customer]);

    const GetCustomerRentObjects = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showRentObjectsLoadingSpiner: true, showErrorInformation: false }));
        setRentObjects([] as RentObjectModel[]);

        getCustomerRentObjects(customer).then((response) => {
            setRentObjects(response.rentObjects);

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showErrorInformation: false }));
            }

        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, showRentObjectsLoadingSpiner: false }));
        });

    }

    const ShowSpinner = () => (
        <div className="spinner-border spinner-border-sm component-header-decoration float-end" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );

    const ShowErrorInformation = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Något gick fel när uthyrningobjekten hämtades, testa gärna att ladda om uthyrningobjekten.
                    </Tooltip>
                }
            >
                <span className="text-warning"><i className="fas fa-exclamation-triangle"></i></span>
            </OverlayTrigger>
        </div>
    );

    const ShowReloadIcon = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Ladda om uthyrningobjekten.
                    </Tooltip>
                }
            >
                <span onClick={GetCustomerRentObjects} className="clickable-icon"><i className="fas fa-redo-alt"></i></span>
            </OverlayTrigger>
        </div>
    );

    const ShowNotAuthenticatedIcon = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Kunde inte authenticerad kunden i underliggande system ex: Pm5.
                    </Tooltip>
                }
            >
                <i className="fa-solid fa-circle-exclamation color-warning"></i>
            </OverlayTrigger>
        </div>
    );

    const ShowIcons = () => {
        if (customer.isAuthenticatedToPm5) {
            if (displaySettings.showRentObjectsLoadingSpiner) {
                return (<ShowSpinner />)
            }
            else {
                return (<ShowReloadIcon />)
            }
        }
        else {
            return (<ShowNotAuthenticatedIcon />)
        }
    }

    return (
        <div className="component-container">
            <div className="row">
                <div className="col-12 text-start">
                    <ShowIcons />
                    {displaySettings.showErrorInformation ? <ShowErrorInformation /> : null}
                    <h5 className="component-header-decoration component-header-decoration-border">Uthyrningsobjekt</h5>
                </div>
            </div>
            {rentObjects?.sort((a, b) => ((a.type === 'Lägenhet' ? 'a' : 'b') + a.rentObjectProperty.street).localeCompare(((b.type === 'Lägenhet' ? 'a' : 'b') + b.rentObjectProperty.street))).map(rentObject => {
                return (
                    <CustomerRentObjectComponent customer={customer} rentObject={rentObject} key={rentObject.number} />
                );
            }
            )}


        </div>
    );


}
export default CustomerRentObjectsComponent;