import BaseClient from './BaseClient';

interface sendMailToResponsiblesBody {
    email: string;
    freeTextContent: string;
    name: string;

    customerNumber: string;
    customerName: string;
    customerPhoneNumberHome: string;
    customerPhoneNumberMobile: string;
    customerMail: string;

    rentObjectNumber: string;
}

interface sendInvoiceMailToCustomerBody {
    period: string;
    documentId: string;
    customerNumber: string;
    customerName: string
}

export async function sendMailToResponsibles(sendMailToResponsiblesBody: sendMailToResponsiblesBody): Promise<boolean> {
    try {
        await BaseClient.post('/mail/send/responsibles', sendMailToResponsiblesBody).then(() => {});

        return true;
    } catch (error) {
        return false;
    }
}

export async function sendInvoiceMailToCustomer(sendInvoiceMailToCustomerBody: sendInvoiceMailToCustomerBody): Promise<boolean> {
    try {
        await BaseClient.post(`/mail/send/customer/${sendInvoiceMailToCustomerBody.customerNumber}/invoice/${sendInvoiceMailToCustomerBody.documentId}`, sendInvoiceMailToCustomerBody).then(() => {});

        return true;
    } catch (error) {
        return false;
    }
}