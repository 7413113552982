import { useState } from "react";
import { useHistory } from "react-router-dom";

const SearchSocialSecurityInputComponent = ({ socialsecuritynumber, buttonWarning }: { socialsecuritynumber: string, buttonWarning : boolean }) => {

    const history = useHistory();

    const [inputSocialsecuritynumber, setInputSocialsecuritynumber] = useState<string>(socialsecuritynumber);

    const GetCustomerByNewSocialSecurityNumber = () => {
        history.push(`/socialsecuritynumber/${inputSocialsecuritynumber}`);
    }
 
    const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            GetCustomerByNewSocialSecurityNumber();
        }
    };

    return (<div className="input-group">
            <input type="text" className="form-control search-input-medium-width" placeholder="Personnummer (YYYYMMDD-XXXX)" aria-label="Personnummer" aria-describedby="button-addon2" onKeyUp={handleKeyboardEvent} value={inputSocialsecuritynumber} onChange={(e) => setInputSocialsecuritynumber(e.target.value)} />
        <button className={buttonWarning ? 'btn btn-warning col-12 col-md-auto' : 'btn btn-secondary col-12 col-md-auto'} type="button" onClick={() => GetCustomerByNewSocialSecurityNumber()}><span className="me-2"><i className="fas fa-user"></i></span>Sök personnummer</button>
        </div>);
}

export default SearchSocialSecurityInputComponent