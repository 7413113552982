import React from 'react';
import { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import './App.css';

import Dashboard from './components/Dashboard';

import { Customer } from './models/CustomerModels';
import SearchPhonenumberComponent from './components/SearchPhonenumberComponent';
import SearchSocialSecurityComponent from './components/SearchSocialSecurityComponent';
import BaseLayout from './layout/BaseLayout';
import LoginCallbackComponent from './authentication/components/LoginCallbackComponent';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authentication/msalConfig';

const App = () => {
    const { instance, accounts, inProgress } = useMsal();

    const [customer, setCustomer] = useState<Customer>();
    const [phoneNumber, setPhoneNumber] = useState('');

    const onPhoneNumberChanged = (customer: Customer, phoneNumber:string) => {
        setCustomer(customer);
        setPhoneNumber(phoneNumber);
    }

    const onSocialSecurityChanged = (customer: Customer) => {
        setCustomer(customer);
    }

    useEffect(() => {
    }, [customer]);

    useEffect(() => {
        if (accounts.length === 0) {
            const currentPageUrl = window.location.href;
            sessionStorage.setItem('pageUrl', currentPageUrl);

            const loginRedirectInitiated = sessionStorage.getItem("loginRedirectInitiated");

            if (!loginRedirectInitiated && inProgress !== "startup") {
                sessionStorage.setItem("loginRedirectInitiated", "true");

                instance.loginRedirect(loginRequest).catch((e) => {
                    console.log(e);
                }).finally(() => {
                    sessionStorage.setItem("loginRedirectInitiated", "false");
                });
            }
        }
    }, [instance, accounts, inProgress]);

    return (
        <div className="App">
            <BaseLayout customer={customer}>
                <Router>
                    <Switch>
                        <Route path="/loginCallback" component={LoginCallbackComponent}></Route>
                        <Route path="/phonenumber/:phonenumber" component={
                            customer ? () => <Dashboard customer={customer}></Dashboard> : () => <SearchPhonenumberComponent onPhoneNumberChanged={onPhoneNumberChanged}></SearchPhonenumberComponent> } />
                        <Route path="/socialsecuritynumber/:socialsecuritynumber" component={
                            customer ? () => <Dashboard customer={customer}></Dashboard> : () => <SearchSocialSecurityComponent onSocialSecurityChanged={onSocialSecurityChanged}></SearchSocialSecurityComponent>} />
                        <Route path="/" component={() => <SearchPhonenumberComponent onPhoneNumberChanged={onPhoneNumberChanged} />}  />
                    </Switch>
                </Router>
            </BaseLayout>
        </div>
    );
}
export default App;
