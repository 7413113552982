import { useState } from "react";
import { useHistory } from "react-router-dom";

const SearchPhonenumberInputComponent = ({ phonenumber, buttonWarning = false }: { phonenumber: string, buttonWarning: boolean }) => {

  const history = useHistory();

  const [inputPhoneNumber, setInputPhoneNumber] = useState<string>(phonenumber);

    const GetCustomerByNewPhoneNumber = () => {
    history.push(`/phonenumber/${inputPhoneNumber}`);
  }

    const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            GetCustomerByNewPhoneNumber();
        }
    };

  return (<div className="input-group">
      <input type="text" className="form-control search-input-medium-width" placeholder="Telefonnummer" aria-label="Telefonnummer" aria-describedby="button-addon2" onKeyUp={handleKeyboardEvent} value={inputPhoneNumber} onChange={(e) => setInputPhoneNumber(e.target.value)} />
      <button className={buttonWarning ? 'btn btn-warning col-12 col-md-auto' : 'btn btn-secondary col-12 col-md-auto' } type="button" id="button-addon2" onClick={() => GetCustomerByNewPhoneNumber()}><span className="me-2"><i className="fas fa-phone"></i></span>Sök telefon</button>
  </div>);
}

export default SearchPhonenumberInputComponent