import { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Customer } from '../models/CustomerModels';

import { RentObjectContactModel } from '../models/RentObjectModels';
import { sendMailToResponsibles } from '../services/SendMailService';

interface DisplaySettings {
    showSendMailToResponsilbesLoadingSpiner: boolean;
    mailSentToResponsibles: boolean;
    mailSentToResponsiblesException: boolean;
}


const CustomerRentObjectResponsiblesContact = ({ contacts, customer, label, rentObjectNumber }: { contacts: RentObjectContactModel[], customer:Customer, label: string, rentObjectNumber: string }) => {

    const [freeTextContentToResponsibles, setFreeTextContentToResponsibles] = useState<string>('');
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ mailSentToResponsibles: false, showSendMailToResponsilbesLoadingSpiner: false} as DisplaySettings);

    const ShowSpinner = () => (
        <div>
            <div className="spinner-border spinner-border-sm component-header-decoration float-end ms-2 mt-1" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            Skickar...
        </div>
    );

    const OnExitMailResponsilbes = () => {
        setDisplaySettings((prevState) => ({ ...prevState, mailSentToResponsibles: false, mailSentToResponsiblesException: false }))
        setFreeTextContentToResponsibles('');
    };


    const popoverMailResponsibles = (label: string) => {
        return (
            <Popover id="popover-positioned-left">
                <h3 className="popover-header">Maila {label}</h3>

                <div className="position-absolute top-0 end-0 pt-1 pe-2">
                    <span className="fs-5" onClick={() => document.body.click()}><i className="fas fa-times"></i></span>
                </div>
                
                <div className="p-3">
                    <div className="mb-2">
                        Meddela {label} att kontakta kund.
                    </div>
                    <div>
                        Anpassat meddelande:
                        <textarea className="w-100" rows={4} disabled={displaySettings.showSendMailToResponsilbesLoadingSpiner || displaySettings.mailSentToResponsibles} onChange={e => setFreeTextContentToResponsibles(e.target.value)} value={freeTextContentToResponsibles}></textarea>
                    </div>
                    <div className="w-100 text-end">
                        {displaySettings.showSendMailToResponsilbesLoadingSpiner ? <ShowSpinner /> : null}
                        {displaySettings.mailSentToResponsiblesException ? <div className="text-danger mb-2">Något gick fel, testa att skicka igen.</div> : null}
                        {displaySettings.showSendMailToResponsilbesLoadingSpiner || displaySettings.mailSentToResponsibles ? null : <button className="btn btn-outline-primary btn-sm" onClick={() => trySendMailToResponsibles()}><i className="fas fa-envelope"></i> Skicka Mail</button>}
                        {displaySettings.mailSentToResponsibles ? <div>Mailet är skickat.</div> : null}
                    </div>
                </div>
            </Popover>
        )
    };

    const trySendMailToResponsibles = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showSendMailToResponsilbesLoadingSpiner: true, mailSentToResponsiblesException: false }));

        sendMailToResponsibles({
                email: contacts[0].email,
                name: contacts[0].name,
                rentObjectNumber: rentObjectNumber,
                freeTextContent: freeTextContentToResponsibles,
                customerNumber: customer.id,
                customerMail: customer.email,
                customerName: (customer.firstName + ' ' + customer.lastName),
                customerPhoneNumberHome: customer.phoneHome,
                customerPhoneNumberMobile: customer.phoneMobile
            })
            .then((response) => {

                if (response) {
                    setDisplaySettings((prevState) => ({ ...prevState, mailSentToResponsibles: true }));
                }
                else {
                    setDisplaySettings((prevState) => ({ ...prevState, mailSentToResponsiblesException: true }));
                }
            })
            .finally(() => {
                setDisplaySettings((prevState) => ({ ...prevState, showSendMailToResponsilbesLoadingSpiner: false }));
            });
    }


    return(
        <div className="pb-3">
            <div className="row">
                <div className="col-12 text-start">{label}: {contacts[0].name}</div>
            </div>
            <div className="row">
                <div className="col-12 text-start"><a href={'mailto:' + contacts[0].email}>{contacts[0].email}</a></div>
            </div>
            <div className="row">
                <div className="col-12 text-start">{contacts[0].phoneWork} (<span>{contacts[0].phone}</span>)</div>
            </div>
            <div className="row">
                <div className="col-12 text-start">
                    <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popoverMailResponsibles(label)} onExited={OnExitMailResponsilbes}>
                        <span className="d-inline-block clickable-icon">
                            <i className="fas fa-envelope"></i>
                        </span>
                    </OverlayTrigger>
                </div>                
            </div>
        </div>
    );


}
export default CustomerRentObjectResponsiblesContact;