import { useState } from 'react';
import { Customer } from '../models/CustomerModels';

import { RentObjectBuildingModel } from '../models/RentObjectModels';
import CustomerRentObjectBuildingElevatorsComponent from './CustomerRentObjectBuildingElevatorsComponent';
import CustomerRentObjectBuildingStaircaseComponent from './CustomerRentObjectBuildingStaircaseComponent';
import CustomerRentObjectBuildingWashroomComponent from './CustomerRentObjectBuildingWashroomComponent';

const CustomerRentObjectBuildingComponent = ({ customer, buildingObject }: { customer:Customer, buildingObject: RentObjectBuildingModel }) => {

    const [showCompnent, setShowCompnent] = useState<Boolean>(false);

    const TryDisplayVariable = ({ label, value }: { label: string, value: any }) => {
        if (value) {
            return (<div className="row" >
                <div className="col-5 text-start">{label}</div>
                <div className="col-7 text-start">{value}</div>
            </div>);
        }

        return (<div></div>)
    }

    if (!(buildingObject.buildingNumber || buildingObject.buildingName)) {
        return (<div></div>)
    }
    else if (showCompnent) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(false)}>
                        <span>Byggnadsinformation</span>
                        <span className="position-absolute btn-toggle-groups">
                            <i className="fa-solid fa-caret-down"></i>
                        </span>
                    </div>
                </div>
                <div className="row ms-1">
                    <TryDisplayVariable label="Byggnadsnummer:" value={buildingObject.buildingNumber} />
                    <TryDisplayVariable label="Byggnadsnamn:" value={buildingObject.buildingName} />
                </div>


                <div className="row ms-1">
                    <CustomerRentObjectBuildingStaircaseComponent customer={customer} buildingObject={buildingObject} />
                </div>
                <div className="row ms-1">
                    <CustomerRentObjectBuildingElevatorsComponent customer={customer} buildingObject={buildingObject} />
                </div>
                <div className="row ms-1">
                    <CustomerRentObjectBuildingWashroomComponent customer={customer} buildingObject={buildingObject} />
                </div>

            </div>
        );
    }
    else {
        return (<div>
            <div className="row">
                <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(true)}>
                    <span>Byggnadsinformation</span>
                    <span className="position-absolute btn-toggle-groups">
                        <i className="fa-solid fa-caret-left"></i>
                    </span>
                </div>
            </div>
        </div>);
    }
}
export default CustomerRentObjectBuildingComponent;