import { Customer } from '../models/CustomerModels';
import { Invoice } from '../models/InvoiceModels';
import BaseClient from './BaseClient';

export async function getInvoices({ id }: Customer): Promise<{ invoices: Invoice[], hasError: boolean }> {
    try {
        const data = await BaseClient.get('/customer/' + id + '/invoices').then((response) => {
            if (response.data && response.data.length > 0) {
                return { invoices: response.data, hasError: false };
            }
            else
            {
                return { invoices: [] as Invoice[], hasError: false };
            }
        });

        return data;
    } catch (error) {
        return { invoices: [] as Invoice[], hasError: true };
    }
}