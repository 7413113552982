import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Customer } from '../models/CustomerModels';

import SearchPhonenumberCustomerCardComponent from "./SearchCustomerCardComponent";
import { getCustomerByPhoneNumber } from "../services/CustomerService";
import SearchPhonenumberInputComponent from "./SearchPhonenumberInputComponent";
import SearchSocialSecurityInputComponent from "./SearchSocialSecurityInputComponent";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import { msalInstance } from "../authentication/msalConfig";

interface PhoneNumberProperties {
    onPhoneNumberChanged: (customer: Customer, phoneNumber:string) => void;
}

interface DisplaySettings {
    showGetPhoneNumberExceptions: boolean,
    showGetPhoneNumber : boolean
}

const SearchPhonenumberComponent = ({ onPhoneNumberChanged }: PhoneNumberProperties) => {
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ showGetPhoneNumberExceptions: false, showGetPhoneNumber: false });
    
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const { phonenumber } = useParams<{ phonenumber: string }>();
    const [customers, setCustomers] = useState<Customer[]>();

    const { accounts } = useMsal();
    const account: AccountInfo | null = accounts[0] || null;

    const hasReadWrite = account?.idTokenClaims?.roles?.includes("CustomerSupport.ReadWrite") ?? false;
    const hasImpersonate = account?.idTokenClaims?.roles?.includes("CustomerSupport.Impersonate") ?? false;

    useEffect(() => {

        if (phonenumber && phonenumber !== '') {
            setDisplaySettings((prevState) => ({ ...prevState, showGetPhoneNumber: false }));

            GetCustomerByPhoneNumber(phonenumber);
        } else {
            setDisplaySettings((prevState) => ({ ...prevState, showGetPhoneNumber: true }));
        }
    }, [phonenumber]); // eslint-disable-line react-hooks/exhaustive-deps

    const GetCustomerByPhoneNumber = async (phonenumber: string) => {
        setPhoneNumber(phonenumber);

        setDisplaySettings((prevState) => ({ ...prevState, showGetPhoneNumberExceptions: false }));

        getCustomerByPhoneNumber({ phoneNumber: phonenumber }).then((response) =>
        {
            if ((hasReadWrite && response.customers.length > 1) || (!hasReadWrite && response.customers.length > 0)) {
                setCustomers(response.customers);
            }
            else if (hasReadWrite && response.customers.length === 1) {
                onPhoneNumberChanged(response.customers[0], phonenumber);
            }
            else {
                setDisplaySettings((prevState) => ({ ...prevState, showGetPhoneNumberExceptions: true }))
            }

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showGetPhoneNumberExceptions: true }));
            }
        }).finally(() => {
        });
    }

    const handleImpersonateRedirect = async (customerNumber : string) => {
        if (account) {
            const accessTokenResponse = await msalInstance.acquireTokenSilent({
                scopes: ['openid', 'profile'],
                account: account
            });

            if (accessTokenResponse) {
                const accessToken = accessTokenResponse.accessToken;

                window.open(`${process.env.REACT_APP_IMPERSONATE_URL}/Account/azuread?access_token=${accessToken}&customer_number=${customerNumber}`, 'impersonateTab');
            }
        }
    }

    const SelectCustomer = (customer: Customer) => {
        if (hasReadWrite) {
            onPhoneNumberChanged(customer, phonenumber);
        } else if (hasImpersonate) {
            handleImpersonateRedirect(customer.id);
        }
    }

    const ShowSpinner = () => (
        <div className="spinner-border component-header-decoration mb-1" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );

    const ShowPhonenumberLoading = () => {
        return (
            <div className="component-phonenumber-loader-container">
                <div className="component-phonenumber-loader-content text-center">
                    <div className="row">
                        {displaySettings.showGetPhoneNumberExceptions ?
                            <h1 className="component-header-decoration text-warning">Telefonummer kunde inte hittas!</h1> :
                            <h1 className="component-header-decoration"><ShowSpinner /> Bearbetar</h1> }
                    </div>
                    <div className="row">
                        {displaySettings.showGetPhoneNumberExceptions ?
                            <h6>Telefonnumret kunde inte hittas i systemet, telefonnummer: {phoneNumber}</h6> :
                            <h6>Söker efter kunder bundna till telefonnummer: {phoneNumber}</h6>}
                    </div>
                    <div className="row mt-3">
                        <div>
                            {displaySettings.showGetPhoneNumberExceptions ?
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-xl-6 pb-3 text-end">
                                        <SearchPhonenumberInputComponent phonenumber={phoneNumber} buttonWarning={true}></SearchPhonenumberInputComponent>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 pb-3">
                                        <SearchSocialSecurityInputComponent socialsecuritynumber={''} buttonWarning={true} ></SearchSocialSecurityInputComponent>
                                    </div>
                                </div>: null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const ShowSearchPhoneNumberSocialSecurity = () => {
        return (
            <div className="component-phonenumber-loader-container">
                <div className="component-phonenumber-loader-content text-center">
                    <div className="row">
                        <h1 className="component-header-decoration">Sök efter kund</h1>
                    </div>
                    <div className="row">
                            <h6>Söker efter kunder bundna till telefonnummer: {phoneNumber}</h6>
                    </div>
                    <div className="row mt-3">
                        <div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-xl-6 pb-3 text-end">
                                    <SearchPhonenumberInputComponent phonenumber={phoneNumber} buttonWarning={false}></SearchPhonenumberInputComponent>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6 pb-3">
                                    <SearchSocialSecurityInputComponent socialsecuritynumber={''} buttonWarning={false}></SearchSocialSecurityInputComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const ShowHeaderText = () => {
        if (hasReadWrite) {
            return (<div>
                <h5 className="component-header-decoration component-header-decoration-border text-left">Telefonnumret: {phoneNumber}, hittade flera kunder bundna till personnumret</h5>
            </div>)
        } else if (hasImpersonate) {
            return (<>
                <div className="row">
                    <span className="h5 component-header-decoration text-left">Telefonnumret: {phoneNumber}, klicka på kund för att logga in på kundens mina sidor!</span>
                </div>
                <div className="row">
                    <span className="component-header-information component-header-decoration-border">Ifall ni redan är inloggad på Mina Sidor, kan det resultera i att ni måste först logga ut er/tidigare kund för att logga in ny kund!</span>
                </div>
            </>)
        }
        else {
            return <></>
        }
    }

    const TextFoundMoreThanOne = () =>
    (
        <div>
            <ShowHeaderText />
            <div>
                {customers?.map(customer => {
                    return (<div className="clickable p-2" key={customer.id} onClick={() => SelectCustomer(customer)}>
                            <SearchPhonenumberCustomerCardComponent customer={customer}></SearchPhonenumberCustomerCardComponent>
                        </div>)
                    })}
            </div>
        </div>
    )

    if (!hasReadWrite && !hasImpersonate) {
        return (
            <div className="component-phonenumber-loader-container">
                <div className="component-phonenumber-loader-content text-center">
                <div className="text-center mt-5">För att få tillgång till "{window.location.href}", kontakta ansvarig chef på kundtjänst.
                  </div>
                </div>
            </div>
        );
    }
    else if (displaySettings.showGetPhoneNumber) {
        return <ShowSearchPhoneNumberSocialSecurity />
    }
    else {
        return (customers ? <TextFoundMoreThanOne /> : <ShowPhonenumberLoading />)
    }

    
}
export default SearchPhonenumberComponent;