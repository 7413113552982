import { useState } from 'react';

import { RentObjectModel } from '../models/RentObjectModels';

const CustomerRentObjectRentComponent = ({ rentObject }: { rentObject: RentObjectModel }) => {

    const [showCompnent, setShowCompnent] = useState<Boolean>(false);

    const TryDisplayVariable = ({ label, value }: { label: string, value: any }) => {
        if (value) {
            return (<div className="row" >
                <div className="col-5 text-start">{label}</div>
                <div className="col-7 text-start">{value}</div>
            </div>);
        }

        return (<div></div>)
    }

    if (!(rentObject.rentObjectProperty.baseRent || rentObject.rentObjectProperty.extraFee || rentObject.rentObjectProperty.rentDescription)) {
        return (<div></div>)
    }
    else if (showCompnent) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(false)}>
                        <span>Hyra</span>
                        <span className="position-absolute btn-toggle-groups">
                            <i className="fa-solid fa-caret-down"></i>
                        </span>
                    </div>
                </div>
                <div className="row ms-1">
                    <TryDisplayVariable label="Grundhyra:" value={rentObject.rentObjectProperty.baseRent} />
                    <TryDisplayVariable label="Extra avgifter:" value={rentObject.rentObjectProperty.extraFee} />
                    <TryDisplayVariable label="Beskrivning:" value={rentObject.rentObjectProperty.rentDescription} />
                </div>
            </div>
        );
    }
    else {
        return (<div>
            <div className="row">
                <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(true)}>
                    <span>Hyra</span>
                    <span className="position-absolute btn-toggle-groups">
                        <i className="fa-solid fa-caret-left"></i>
                    </span>
                </div>
            </div>
        </div>);
    }
}
export default CustomerRentObjectRentComponent;