import { CreateServiceReportRequestModel } from "../models/ServiceReportModels";
import BaseClient from "./BaseClient";

export async function createServiceReport(createServiceReportPestBody: CreateServiceReportRequestModel): Promise<boolean> {
    try {
        await BaseClient.post(`/customer/${createServiceReportPestBody.customerNumber}/servicereport`, createServiceReportPestBody).then(() => {});

        return true;
    } catch (error) {
        return false;
    }
}