import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { msalInstance, scopes } from '../authentication/msalConfig';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API
})

instance.interceptors.response.use((response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            const currentUrl = window.location.href;

            if (currentUrl.startsWith(process.env.REACT_APP_MSAL_DEFAULT_URL as string)) {
                window.location.href = process.env.REACT_APP_MSAL_REDIRECT_URL as string;
            } else if (currentUrl.startsWith(process.env.REACT_APP_MSAL_ALTERNATIVE_DEFAULT_URL as string)) {
                window.location.href = process.env.REACT_APP_MSAL_ALTERNATIVE_REDIRECT_URL as string;
            } else {
                window.location.href = process.env.REACT_APP_MSAL_REDIRECT_URL as string;
            }
        }
        return Promise.reject(error);
    }
);

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
}

const onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const account = msalInstance.getAllAccounts()[0];

    if (account) {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
            scopes: scopes,
            account: account
        });

        if (accessTokenResponse) {
            const accessToken = accessTokenResponse.accessToken;

            if (config.headers && accessToken) {
                config.headers['Authorization'] = 'Bearer ' + accessToken;
            }
        }
    }

    return config;
}

instance.interceptors.request.use(onRequest, onRequestError);

export default instance;