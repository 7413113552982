import { Customer } from '../models/CustomerModels';
import { RentObjectModel } from '../models/RentObjectModels';
import BaseClient from './BaseClient';

export async function getCustomerRentObjects({ id }: Customer): Promise<{ rentObjects: RentObjectModel[], hasError: boolean }> {
    try {
        const data = await BaseClient.get('/customer/' + id + '/rentobjects').then((response) => {
            if (response.data && response.data && response.data.length > 0) {
                return { rentObjects: response.data, hasError: false };
            }
            else
            {
                return { rentObjects: [] as RentObjectModel[], hasError: false };
            }
        });

        return data;
    } catch (error) {
        return { rentObjects: [] as RentObjectModel[], hasError: true };
    }
}