import moment from 'moment';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Customer } from '../models/CustomerModels'
import { ServiceObjectModel } from '../models/CustomerServiceReportModels';
import { getCustomerRentObjectServiceObjects } from '../services/CustomerServiceObjectsService';
import CustomerServiceObjectReportsComponent from './CustomerServiceObjectReportsComponent';

interface CustomerserviceReportsProperties {
    customer: Customer
}

interface DisplaySettings {
    showServiceObjectLoadingSpiner: boolean
    showErrorInformation: boolean
    showAllServiceReports: boolean
}


const CustomerServiceReportsComponent = ({ customer }: CustomerserviceReportsProperties) => {

    const [serviceObjects, setServiceObjects] = useState<ServiceObjectModel[]>([] as ServiceObjectModel[]);
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({} as DisplaySettings);

    useEffect(() => {
        GetServiceReports();
    }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

    const GetServiceReports = () => {
        if (!customer.isAuthenticatedToPm5) {
            return;
        }

        setDisplaySettings((prevState) => ({ ...prevState, showServiceObjectLoadingSpiner: true, showErrorInformation: false,showAllServiceReports:false }));
        setServiceObjects([] as ServiceObjectModel[]);

        getCustomerRentObjectServiceObjects(customer).then((response) => {
            setServiceObjects(response.serviceObjects);

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showErrorInformation: true }));
            }
        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, showServiceObjectLoadingSpiner: false }));
        });
    }

    const ShowSpinner = () => (
        <div className="spinner-border spinner-border-sm component-header-decoration float-end" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );

    const ShowErrorInformation = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Något gick fel när serviceärendena hämtades, testa gärna att ladda om serviceärendena.
                    </Tooltip>
                }
            >
                <span className="text-warning"><i className="fas fa-exclamation-triangle"></i></span>
            </OverlayTrigger>
        </div>
    );

    const ShowReloadIcon = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Ladda om serviceärendena.
                    </Tooltip>
                }
            >
                <span onClick={GetServiceReports} className="clickable-icon"><i className="fas fa-redo-alt"></i></span>
            </OverlayTrigger>
        </div>
    );

    const ShowNotAuthenticatedIcon = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Kunde inte authenticerad kunden i underliggande system ex: Pm5.
                    </Tooltip>
                }
            >
                <i className="fa-solid fa-circle-exclamation color-warning"></i>
            </OverlayTrigger>
        </div>
    );

    const ShowIcons = () => {
        if (customer.isAuthenticatedToPm5) {
            if (displaySettings.showServiceObjectLoadingSpiner) {
                return (<ShowSpinner />)
            }
            else {
                return (<ShowReloadIcon />)
            }
        }
        else {
            return (<ShowNotAuthenticatedIcon />)
        }
    }

    const filterServiceObjects = (serviceObject : ServiceObjectModel) => {
        if (displaySettings.showAllServiceReports || moment(serviceObject.lastUpdate).isAfter(moment().subtract(6, 'month'))) {
            return true;
        }
        return false;
    }

    const ShowViewMoreServiceReports = () => (
        <div className="text-start pb-5">
            <div className="float-start">
                <span className="fst-italic" style={{ display: (displaySettings.showAllServiceReports ? 'none' : 'block') }}>Det finns aktiva serviceärenden, äldre än 6 månader</span>
                <span className="fst-italic" style={{ display: (displaySettings.showAllServiceReports ? 'block' : 'none') }}>Visar alla aktiva serviceärenden</span>
            </div>
            <div className="float-end">
                <div>
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => setDisplaySettings((prevState) => ({ ...prevState, showAllServiceReports: true }))} style={{ display: (displaySettings.showAllServiceReports ? 'none' : 'block') }}>Visa alla serviceärenden</button>
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => setDisplaySettings((prevState) => ({ ...prevState, showAllServiceReports: false }))} style={{ display: (displaySettings.showAllServiceReports ? 'block' : 'none') }}>Göm äldre serviceärenden</button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="component-container">
            <div className="row">
                <div className="col-12 text-start">
                    <ShowIcons />
                    {displaySettings.showErrorInformation ? <ShowErrorInformation /> : null}

                    <h5 className="component-header-decoration component-header-decoration-border">Serviceärenden</h5>
                </div>
            </div>

            <div>
                {serviceObjects?.filter(filterServiceObjects)?.map(serviceObject => {
                    return (<div key={serviceObject.id}>
                        <div className="row">
                            <div className="col-12 text-start color-default fw-bold">{serviceObject.description} <span className="fst-italic">{serviceObject.objectType}</span></div>
                        </div>

                        <CustomerServiceObjectReportsComponent serviceObjectReports={serviceObject.serviceReports} />

                        {serviceObjects[serviceObjects.length - 1].id !== serviceObject.id ? <div className="mt-3 mb-3"><div className="border-top"></div></div> : <div></div>}
                    </div>)
                })}
            </div>

            {serviceObjects.some(serviceObject => (moment(serviceObject.lastUpdate).isBefore(moment().subtract(6, 'month')))) ? <ShowViewMoreServiceReports /> : null}
        </div>
    );
}
export default CustomerServiceReportsComponent;