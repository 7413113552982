import { Customer } from '../models/CustomerModels'
import CustomerComponent from './CustomerComponent';
import CustomerServiceReportsComponent from './CustomerServiceReportsComponent'
import CustomerInvoicesComponent from './CustomerInvoicesComponent';
import CustomerRentObjectsComponent from './CustomerRentObjectsComponent';

interface DashboardProperties {
    customer: Customer
}

const Dashboard = ({ customer }: DashboardProperties) => {

    return (
        <div>
            <div className="row pb-3">
                <div className="col-6">
                    <CustomerComponent customer={customer}></CustomerComponent>
                </div>

                <div className="col-6">
                    <CustomerRentObjectsComponent customer={customer}></CustomerRentObjectsComponent>
                </div>
            </div>
            <div className="row pb-3">
                <div className="col-12"> 
                    <CustomerServiceReportsComponent customer={customer}></CustomerServiceReportsComponent>
                </div>
            </div>
            <div className="row pb-3">
                <div className="col-12">
                    <CustomerInvoicesComponent customer={customer}></CustomerInvoicesComponent>
                </div>
            </div>
        </div>
    );
}
export default Dashboard;
