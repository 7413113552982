import { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Customer } from '../models/CustomerModels';

import { Invoice } from '../models/InvoiceModels';
import { getInvoices } from '../services/CustomerInvoiceService';
import CustomerInvoiceComponent from './CustomerInvoiceComponent';

interface DisplaySettings {
    showInvoiceLoadingSpiner: boolean
    showErrorInformation: boolean
}

const CustomerInvoicesComponent = ({ customer }: { customer: Customer }) => {

    const [invoices, setInvoices] = useState<Invoice[]>();
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ showInvoiceLoadingSpiner : false } as DisplaySettings);

    
    const GetInvoices = () => {
        if (!customer.isAuthenticatedToPm5) {
            return;
        }

        setDisplaySettings((prevState) => ({ ...prevState, showInvoiceLoadingSpiner: true, showErrorInformation: false }));
        setInvoices([] as Invoice[]);

        getInvoices(customer).then((response) => {

            setInvoices(response.invoices);

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showErrorInformation: true }));
            }

        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, showInvoiceLoadingSpiner: false }));
        });
    }

    useEffect(() => {
        GetInvoices();

    }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

    const ShowSpinner = () => (
        <div className="spinner-border spinner-border-sm component-header-decoration float-end" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );

    const ShowErrorInformation = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Något gick fel när fakturorna hämtades, testa gärna att ladda om fakturorna.
                    </Tooltip>
                }
            >
                <span className="text-warning"><i className="fas fa-exclamation-triangle"></i></span>
            </OverlayTrigger>
        </div>
    );

    const ShowReloadIcon = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Ladda om fakturorna.
                    </Tooltip>
                }
            >
                <span onClick={GetInvoices} className="clickable-icon"><i className="fas fa-redo-alt"></i></span>
            </OverlayTrigger>
        </div>
    );

    const ShowNotAuthenticatedIcon = () => (
        <div className="component-header-decoration float-end">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Kunde inte authenticerad kunden i underliggande system ex: Pm5.
                    </Tooltip>
                }
            >
                <i className="fa-solid fa-circle-exclamation color-warning"></i>
            </OverlayTrigger>
        </div>
    );

    const ShowIcons = () => {
        if (customer.isAuthenticatedToPm5) {
            if (displaySettings.showInvoiceLoadingSpiner) {
                return (<ShowSpinner />)
            }
            else {
                return (<ShowReloadIcon />)
            }
        }
        else {
            return (<ShowNotAuthenticatedIcon />)
        }
    }

    console.log(displaySettings.showInvoiceLoadingSpiner, customer.isAuthenticatedToPm5, "h");
    return (
        <div className="component-container">
            <div className="row">
                <div className="col-12 text-start">
                    <ShowIcons />
                    {displaySettings.showErrorInformation ? <ShowErrorInformation /> : null}
                    <h5 className="component-header-decoration component-header-decoration-border">Fakturor</h5>
                </div>
            </div>
            <div className="row d-flex">
                <table className="mt-2 mx-3 table-unset-flex">
                    <thead>
                        <tr>
                            <th>Faktura Nummer</th>
                            <th>Period</th>
                            <th>BankGiro</th>
                            <th>OCR</th>
                            <th>Att Betala</th>
                            <th>Betalt</th>
                            <th>Förfallodatum</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices?.map(invoice => <CustomerInvoiceComponent invoice={invoice} customer={customer} key={invoice.invoiceNumber} />)}
                    </tbody>
                </table>
            </div>
            
            
        </div>
    );

}
export default CustomerInvoicesComponent;