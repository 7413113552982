import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { SignInButtonComponent } from "../authentication/components/SignInButtonComponent";
import { SignOutButtonComponent } from "../authentication/components/SignOutButtonComponent";
import { Customer } from "../models/CustomerModels";
import logo from "../assets/images/mkblogo-white.png";
import "../styles/Header.css"

const BaseLayout = ({
  children,
  customer,
}: {
  children: JSX.Element;
  customer: Customer | undefined;
}) => {
  const isAuthenticated = useIsAuthenticated();
  const [currentCustomer, setCurrentCustomer] = useState<Customer|undefined>();
  const apiUrl = process.env.REACT_APP_MSAL_REDIRECT_URL;

  useEffect(() => {
    setCurrentCustomer(customer);
  }, [customer]);

  return (
    <>
      <header
        className="navbar align-items-center text-white header-background"
       
      >
        <div className="container-fluid w-50 d-flex flex-column flex-md-row p-4">
          <div className="col-4 col-md-2 mb-3 mb-md-0">
            <a href={apiUrl}>
              <img
                src={logo}
                alt="MKB Logo"
                className="img-fluid"
                style={{ maxHeight: "50px" }}
              />
            </a>
          </div>
          <div className="col-8 col-md-8 text-center mb-3 mb-md-0">
            <h3 className="h3">Logga in som kund</h3>
          </div>
          <div className="col-12 col-md-2 text-nowrap text-center text-md-end">
            {isAuthenticated ? (
              <SignOutButtonComponent />
            ) : (
              <SignInButtonComponent />
            )}
          </div>
        </div>
      </header>
      <div className="container w-50">
        <div className="row">
          {isAuthenticated ? (
            children
          ) : (
            <div className="text-center mt-5">
              Du måste logga in på ditt MKB-konto för att komma in i Kundsupport
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BaseLayout;
