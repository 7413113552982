import { useState } from 'react';
import { Customer } from '../models/CustomerModels';

import { RentObjectBuildingModel } from '../models/RentObjectModels';
import CustomerRentObjectBuildingElevatorComponent from './CustomerRentObjectBuildingElevatorComponent';

const CustomerRentObjectBuildingElevatorsComponent = ({ customer, buildingObject }: { customer:Customer, buildingObject: RentObjectBuildingModel }) => {

    const [showCompnent, setShowCompnent] = useState<Boolean>(true);

    if (!(buildingObject.elevators) || buildingObject.elevators.length === 0) {
        return (<div></div>)
    }
    else if (showCompnent) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(false)}>
                        <span>Hiss</span>
                        <span className="position-absolute btn-toggle-groups">
                            <i className="fa-solid fa-caret-down"></i>
                        </span>
                    </div>
                </div>
                {buildingObject.elevators?.map(elevator => <CustomerRentObjectBuildingElevatorComponent customer={customer} elevator={elevator} key={elevator.elevatorNumber}></CustomerRentObjectBuildingElevatorComponent>)}
            </div>
        );
    }
    else {
        return (<div>
            <div className="row">
                <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(true)}>
                    <span>Hiss</span>
                    <span className="position-absolute btn-toggle-groups">
                        <i className="fa-solid fa-caret-left"></i>
                    </span>
                </div>
            </div>
        </div>);
    }
}
export default CustomerRentObjectBuildingElevatorsComponent;