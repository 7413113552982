import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msalConfig";
import "../../styles/Header.css"

export const SignInButtonComponent = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        const currentPageUrl = window.location.href;
        sessionStorage.setItem('pageUrl', currentPageUrl); 

        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });
    };

    return (
        <button className="btn btn-secondary btn-sm btn-light login-and-out-button-color fw-bold rounded-pill" onClick={handleLogin}>Logga in</button>
    );
};