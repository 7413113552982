import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

const LoginCallbackComponent = () => {
    const { instance } = useMsal();

    useEffect(() => {
        const currentPageUrl = sessionStorage.getItem('pageUrl'); // Retrieve the page URL from session storage

        if (currentPageUrl) {
            window.location.href = currentPageUrl; // Redirect the user back to the original page
        } else {
            window.location.href = process.env.REACT_APP_MSAL_DEFAULT_URL || '';
        }
    }, [instance]);

    return <div>Loading...</div>;
}

export default LoginCallbackComponent;
