import { useState } from 'react';

import { RentObjectModel } from '../models/RentObjectModels';
import CustomerRentObjectRentComponent from './CustomerRentObjectRentComponent';
import CustomerRentObjectRoomDetailsComponent from './CustomerRentObjectRoomDetailsComponent';

interface CustomerRentObjectAttributes {
    rentObject: RentObjectModel
}

const CustomerRentObjectAttributesComponent = ({ rentObject }: CustomerRentObjectAttributes) => {

    const [showCompnent, setShowCompnent] = useState<Boolean>(false);

    const TryDisplayVariable = ({ label, value }: { label: string, value: any }) => {
        if (value) {
            return (<div className="row" >
                <div className="col-5 text-start">{label}</div>
                <div className="col-7 text-start">{value}</div>
            </div>);
        }

        return (<div></div>)
    }

    if (!(rentObject.rentObjectProperty.propertyType || rentObject.rentObjectProperty.propertyName || rentObject.rentObjectProperty.elevator || rentObject.rentObjectProperty.floorNumber || rentObject.rentObjectProperty.livingArea || rentObject.rentObjectProperty.maxNumberOfResidents || rentObject.rentObjectProperty.numberOfRooms || rentObject.rentObjectProperty.state)) {
        return(<div></div>)
    }
    else if (showCompnent) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(false)}>
                        <span>Hyresobjektsinformation</span>
                        <span className="position-absolute btn-toggle-groups">
                            <i className="fa-solid fa-caret-down"></i>
                        </span>
                    </div>
                </div>
                <div className="row ms-1">
                    <TryDisplayVariable label="Hyrobjectstyp:" value={rentObject.rentObjectProperty.propertyType} />
                    <TryDisplayVariable label="Hyrobjectsnamn:" value={rentObject.rentObjectProperty.propertyName} />

                    <TryDisplayVariable label="Våningsnummer:" value={rentObject.rentObjectProperty.floorNumber} />
                    <TryDisplayVariable label="Yta:" value={rentObject.rentObjectProperty.livingArea} />
                    <TryDisplayVariable label="Maximalt antal:" value={rentObject.rentObjectProperty.maxNumberOfResidents} />
                    <TryDisplayVariable label="Antal rum:" value={rentObject.rentObjectProperty.numberOfRooms} />
                    <TryDisplayVariable label="Status:" value={rentObject.rentObjectProperty.state} />
                    <CustomerRentObjectRentComponent rentObject={rentObject} />
                    <CustomerRentObjectRoomDetailsComponent rentObject={rentObject} />
                </div>
            </div>
        );
    }
    else {
        return (<div>
            <div className="row">
                <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(true)}>
                    <span>Hyresobjektsinformation</span>
                    <span className="position-absolute btn-toggle-groups">
                        <i className="fa-solid fa-caret-left"></i>
                    </span>
                </div>
            </div>
        </div>);
    }
}
export default CustomerRentObjectAttributesComponent;