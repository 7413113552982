import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Customer } from '../models/CustomerModels';

import SearchCustomerCardComponent from "./SearchCustomerCardComponent";
import { getCustomerBySocialSecurityNumber } from "../services/CustomerService";
import SearchPhonenumberInputComponent from "./SearchPhonenumberInputComponent";
import SearchSocialSecurityInputComponent from "./SearchSocialSecurityInputComponent";
import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../authentication/msalConfig";

interface SocialSecurityProperties {
    onSocialSecurityChanged: (customer: Customer, socialSecurity: string) => void;
}

interface DisplaySettings {
    showGetSocialSecurityExceptions: boolean
}

const SearchSocialSecurityComponent = ({ onSocialSecurityChanged }: SocialSecurityProperties) => {
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ showGetSocialSecurityExceptions: false });

    const { socialsecuritynumber } = useParams<{ socialsecuritynumber: string }>();

    const [customers, setCustomers] = useState<Customer[]>();
    const [socialSecurityNumber, setSocialSecurityNumber] = useState<string>('');

    const { accounts } = useMsal();
    const account: AccountInfo | null = accounts[0] || null;

    const hasReadWrite = account?.idTokenClaims?.roles?.includes("CustomerSupport.ReadWrite") ?? false;
    const hasImpersonate = account?.idTokenClaims?.roles?.includes("CustomerSupport.Impersonate") ?? false;

    useEffect(() => {
        GetCustomerBySocialSecurity(socialsecuritynumber);
    }, [socialsecuritynumber]); // eslint-disable-line react-hooks/exhaustive-deps

    const GetCustomerBySocialSecurity = async (socialsecuritynumber : string) => {
        setDisplaySettings((prevState) => ({ ...prevState, showGetSocialSecurityExceptions: false }));
        setSocialSecurityNumber(socialsecuritynumber);

        getCustomerBySocialSecurityNumber({ socialSecurityNumber: socialsecuritynumber }).then((response) =>
        {
            if ((hasReadWrite && response.customers.length > 1) || (!hasReadWrite && response.customers.length > 0)) {
                setCustomers(response.customers);
            }
            else if (hasReadWrite && response.customers.length === 1) {
                onSocialSecurityChanged(response.customers[0], socialsecuritynumber);
            }
            else {
                setDisplaySettings((prevState) => ({ ...prevState, showGetSocialSecurityExceptions: true }))
            }

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showGetSocialSecurityExceptions: true }));
            }
        }).finally(() => {
        });
    }

    const handleImpersonateRedirect = async (customerNumber: string) => {
        if (account) {
            const accessTokenResponse = await msalInstance.acquireTokenSilent({
                scopes: ['openid', 'profile'],
                account: account
            });

            if (accessTokenResponse) {
                const accessToken = accessTokenResponse.accessToken;

                window.open(`${process.env.REACT_APP_IMPERSONATE_URL}/Account/azuread?access_token=${accessToken}&customer_number=${customerNumber}`, 'impersonateTab');
            }
        }
    }

    const SelectCustomer = (customer: Customer) =>
    {
        if (hasReadWrite) {
            onSocialSecurityChanged(customer, socialsecuritynumber);
        } else if (hasImpersonate) {
            handleImpersonateRedirect(customer.id);
        }
        
    }

    const ShowSpinner = () => (
        <div className="spinner-border component-header-decoration mb-1" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );

    const ShowPhonenumberLoading = () => {
        return (
            <div className="component-phonenumber-loader-container">
                <div className="component-phonenumber-loader-content text-center">
                    <div className="row">
                        {displaySettings.showGetSocialSecurityExceptions ?
                            <h1 className="component-header-decoration text-warning">Personnumret kunde inte hittas!</h1> :
                            <h1 className="component-header-decoration"><ShowSpinner /> Bearbetar</h1>}
                    </div>
                    <div className="row">
                        {displaySettings.showGetSocialSecurityExceptions ?
                            <h6>Personnumret kunde inte hittas i systemet, personnummer: {socialSecurityNumber}</h6> :
                            <h6>Söker efter kunder bundna till personnummer: {socialSecurityNumber}</h6>}
                    </div>
                    <div className="row mt-3">
                        <div>
                            {displaySettings.showGetSocialSecurityExceptions ?
                                <div className="row">
                                    <div className="col-6 text-end">
                                        <SearchPhonenumberInputComponent phonenumber={''} buttonWarning={true}></SearchPhonenumberInputComponent>
                                    </div>
                                    <div className="col-6">
                                        <SearchSocialSecurityInputComponent socialsecuritynumber={socialSecurityNumber} buttonWarning={true}></SearchSocialSecurityInputComponent>
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const ShowSelectText = () => {
        if (hasReadWrite) {
            return <>hittade flera kunder bundna till personnumret</>
        } else if (hasImpersonate) {
            return <>klicka på kund för att logga in på kundens mina sidor!</>
        }
        else {
            return <></>
        }
    }

    const TextFoundMoreThanOne = () =>
    (
        <div>
            <h5 className="component-header-decoration component-header-decoration-border text-left">Personnummer: {socialSecurityNumber}, <ShowSelectText /></h5>
            <div>
                {customers?.map(customer => {
                    return (<div className="clickable p-2" key={customer.id} onClick={() => SelectCustomer(customer)}>
                        <SearchCustomerCardComponent customer={customer}></SearchCustomerCardComponent>
                    </div>)
                })}
            </div>
        </div>
    )

    return (customers ? <TextFoundMoreThanOne /> : <ShowPhonenumberLoading />)
}
export default SearchSocialSecurityComponent;