import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Customer } from '../models/CustomerModels';
import BaseClient from './BaseClient';

interface CustomerByPhoneNumberProperties {
    phoneNumber : string;
}

export async function getImpersonate(customerNumber: string) {
    await BaseClient.get(process.env.REACT_APP_IMPERSONATE_URL || '', {
        headers: {
            'x-mkb-customernumber' : customerNumber
        }
    }).then(() => { });
}

export async function getCustomerByPhoneNumber({ phoneNumber }: CustomerByPhoneNumberProperties): Promise<{ customers: Customer[], hasError: boolean }> {
    try {
        const data = await BaseClient.get('/customer/phonenumber/' + phoneNumber).then((response) => {
            if (response.data && response.data.length > 0) {

                var customers = response.data as Customer[];

                customers.forEach(customer => {
                    customer.rentObjects.forEach(rentObject => {
                        rentObject.startDate = moment(rentObject.startDate);
                        rentObject.endDate = moment(rentObject.endDate);
                    })
                });

                return { customers: customers, hasError: false };
            }
            else {
                return { customers: [] as Customer[], hasError: false };
            }
        });

        return data;
    } catch (error) {
        return { customers: [] as Customer[], hasError: true };
    }
}

export async function getCustomerBySocialSecurityNumber({ socialSecurityNumber }: { socialSecurityNumber: string }): Promise<{ customers: Customer[], hasError: boolean }> {
    try {
        const data = await BaseClient.get('/customer/socialsecuritynumber/' + socialSecurityNumber).then((response) => {
            if (response.data && response.data.length > 0) {

                var customers = response.data as Customer[];

                customers.forEach(customer => {
                    customer.rentObjects.forEach(rentObject => {
                        rentObject.startDate = moment(rentObject.startDate);
                        rentObject.endDate = moment(rentObject.endDate);
                    })
                });

                return { customers: customers, hasError: false };
            }
            else {
                return { customers: [] as Customer[], hasError: false };
            }
        });

        return data;
    } catch (error) {
        return { customers: [] as Customer[], hasError: true };
    }
}