import { Customer } from '../models/CustomerModels'
import { RentObjectModel } from '../models/RentObjectModels'
import { CreateServiceReportComponentProperties } from '../models/ServiceReportModels';

import CustomerRentObjectAttributes from './CustomerRentObjectAttributesComponent';
import CustomerRentObjectResponsibles from './CustomerRentObjectResponsiblesComponent';
import CustomerRentObjectBuildingComponent from './CustomerRentObjectBuildingComponent';
import CreateServiceReportComponent from './CreateServiceReportComponent';


const CustomerRentObjectComponent = ({ customer, rentObject }: { customer:Customer, rentObject: RentObjectModel }) => {

    const serviceReportPest: CreateServiceReportComponentProperties = {
        customnerNumber: customer.id,
        pm5Id: undefined,
        objectNumber: rentObject.number,
        contactName: `${customer.firstName} ${customer.lastName}`,
        contactPhone: customer.phoneMobile && customer.phoneMobile.length > 0 ? customer.phoneMobile : customer.phoneHome,
        contactEmail: customer.email,

        showPestDropdown: true,

        popoverLabel: 'Skapa skadedjursärende',
        iconClass: 'fas fa-bug',
    }

    const serviceReportRegulare: CreateServiceReportComponentProperties = {
        customnerNumber: customer.id,
        pm5Id: undefined,
        objectNumber: rentObject.number,
        contactName: `${customer.firstName} ${customer.lastName}`,
        contactPhone: customer.phoneMobile && customer.phoneMobile.length > 0 ? customer.phoneMobile : customer.phoneHome,
        contactEmail: customer.email,

        showPestDropdown: false,

        popoverLabel: 'Skapa serviceärende',
        iconClass: 'fas fa-tools',
    }

    return (
        <div>
            <div className="row" >
                {(() => {
                    if (rentObject.endDate.isValid())
                        return (<div className="col-9 text-start fw-bold color-red">{rentObject.type}: {rentObject.rentObjectProperty.street} {rentObject.rentObjectProperty.officialNumber}</div>)
                    else if (rentObject.status === 'Draft')
                        return (<div className="col-9 text-start fw-bold color-orange">{rentObject.type}: {rentObject.rentObjectProperty.street} {rentObject.rentObjectProperty.officialNumber}</div>)
                    else if (rentObject.status === 'Future')
                        return (<div className="col-9 text-start fw-bold color-blue">{rentObject.type}: {rentObject.rentObjectProperty.street} {rentObject.rentObjectProperty.officialNumber}</div>)
                    else
                        return (<div className="col-9 text-start fw-bold color-default">{rentObject.type}: {rentObject.rentObjectProperty.street} {rentObject.rentObjectProperty.officialNumber}</div>)
                })()}
                <div className="col-3 text-end color-default">
                    <CreateServiceReportComponent properties={serviceReportRegulare} />
                    <CreateServiceReportComponent properties={serviceReportPest}/>
                </div>
            </div>
            <div className="row ms-1">
                {(() => {
                    if (rentObject.endDate.isValid())
                        return (<div className="col-12 text-start fw-bold">Kontrakt avslutas {rentObject.endDate.format('YYYY-MM-DD')} </div>)
                    else if (rentObject.status === 'Draft')
                        return (<div className="col-12 text-start fw-bold">Kontrakt är ett förslag</div>)
                    else if (rentObject.status === 'Future')
                        return (<div className="col-12 text-start fw-bold">Kontrakt börjar {rentObject.startDate.format('YYYY-MM-DD')}</div>)
                })()}
            </div>
            <div className="row ms-1">
                <div className="col-12 text-start">{rentObject.rentObjectProperty.zipCode} {rentObject.rentObjectProperty.city} </div>
            </div>

            <div className="row ms-1">
                <div className="col-12 text-start">{rentObject.number} {rentObject.description}</div>
            </div>

            <div className="row ms-1">
                <div className="col-12 text-start fst-italic">{rentObject.rentObjectProperty.officialDistrict}</div>
            </div>

            <CustomerRentObjectAttributes rentObject={rentObject} />

            <CustomerRentObjectBuildingComponent customer={customer} buildingObject={rentObject.rentObjectProperty.building} />

            <CustomerRentObjectResponsibles rentObject={rentObject} customer={customer} />

            <div className="mt-3 mb-3"><div className="border-top"></div></div>
        </div>
    );

    
}
export default CustomerRentObjectComponent;