import { AccountInfo } from '@azure/msal-common';
import { useMsal } from '@azure/msal-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { msalInstance } from '../authentication/msalConfig';
import { Customer } from '../models/CustomerModels'
import { getImpersonate } from '../services/CustomerService';

interface CustomerComponentProperties {
    customer: Customer
}


const ShowCustomerAdminUrlLink = (parameters: {url:string}) => (
    <OverlayTrigger
        placement='top'
        overlay={
            <Tooltip>
                Länk till kundens Pm5 sida 
            </Tooltip>
        }>
        <a href={parameters.url} className="clickable-icon" target="pm5gui"><i className="fa-solid fa-up-right-from-square"></i></a>
    </OverlayTrigger>
);

const ShowCustomerImpersonationUrlLink = ({ customerNumber }: { customerNumber: string }) => {
    const { accounts } = useMsal();
    const account: AccountInfo | null = accounts[0] || null;

    const hasCorrectRole = account?.idTokenClaims?.roles?.includes("CustomerSupport.Impersonate") ?? false;

    const handleRedirect = async () => {

        const account = msalInstance.getAllAccounts()[0];

        if (account) {
            const accessTokenResponse = await msalInstance.acquireTokenSilent({
                scopes: ['openid', 'profile'],
                account: account
            });

            if (accessTokenResponse) {
                const accessToken = accessTokenResponse.accessToken;

                window.open(`${process.env.REACT_APP_IMPERSONATE_URL}/Account/azuread?access_token=${accessToken}&customer_number=${customerNumber}`, 'impersonateTab');
            }
        }
    }

    if (hasCorrectRole) {
        return (<OverlayTrigger
            placement='top'
            overlay={
                <Tooltip>
                    Besök kundsidan som kunden.
                </Tooltip>
            }>
            <i onClick={handleRedirect} className="clickable-icon fa-solid fa-people-arrows me-1"></i>
        </OverlayTrigger>)
    }
    else {
        return (<></>)
    }
};

const CustomerComponent = ({ customer }: CustomerComponentProperties) => {
    return (
        <div className="component-container">
            <div className="row">
                <div className="col-12 text-start">
                    <h4 className="component-header-name-decoration component-header-decoration-border">{customer.firstName} {customer.lastName}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-5 text-start">Kundnummer:</div>
                <div className="col-5 text-start">{customer.id} </div>
                <div className="col-2 text-end"> <ShowCustomerImpersonationUrlLink customerNumber={customer.id} /><ShowCustomerAdminUrlLink url={customer.customerAdminUrl} /></div>
            </div>
            <div className="row">
                <div className="col-5 text-start">Personnummer:</div>
                <div className="col-7 text-start">{customer.identificationNumber}</div>
            </div>
            <div className="row">
                <div className="col-5 text-start">Kundtyp:</div>
                <div className="col-7 text-start">{customer.customerType}</div>
            </div>
            <div className="row">
                <div className="col-5 text-start">Epost:</div>
                <div className="col-7 text-start"><a href={'mailto:' + customer.email}>{customer.email}</a></div>
            </div>
            <div className="row">
                <div className="col-5 text-start">Telefon hem:</div>
                <div className="col-7 text-start">{customer.phoneHome}</div>
            </div>
            <div className="row">
                <div className="col-5 text-start">Telefon mobil:</div>
                <div className="col-7 text-start">{customer.phoneMobile}</div>
            </div>

        </div>
        );
}
export default CustomerComponent;
