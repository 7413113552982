import { Customer } from "../models/CustomerModels";
import { RentObjectBuildingElevatorModel } from "../models/RentObjectModels";
import { CreateServiceReportComponentProperties } from "../models/ServiceReportModels";
import CreateServiceReportComponent from "./CreateServiceReportComponent";


const CustomerRentObjectBuildingElevatorComponent = ({ customer, elevator }: { customer: Customer, elevator: RentObjectBuildingElevatorModel }) => {

    const serviceReportRegulare: CreateServiceReportComponentProperties = {
        customnerNumber: customer.id,
        pm5Id: elevator.elevatorNumber,
        objectNumber: undefined,
        contactName: `${customer.firstName} ${customer.lastName}`,
        contactPhone: customer.phoneMobile && customer.phoneMobile.length > 0 ? customer.phoneMobile : customer.phoneHome,
        contactEmail: customer.email,

        showPestDropdown: false,

        popoverLabel: 'Skapa serviceärende för hiss.',
        iconClass: 'fas fa-tools',
    }

    const TryDisplayVariable = ({ label, value }: { label: string, value: any }) => {
        if (value) {
            return (<div className="row" >
                <div className="col-5 text-start">{label}</div>
                <div className="col-7 text-start">{value}</div>
            </div>);
        }

        return (<div></div>)
    }

    return (
        <div className="row ms-1 mb-2">
            <div className="row pe-0">
                <div className="col-5 text-start">Hissnummer</div>
                <div className="col-5 text-start">{elevator.elevatorNumber}</div>
                <div className="col-2 text-end color-default pe-0">
                    <CreateServiceReportComponent properties={serviceReportRegulare} />
                </div>
            </div>
            <TryDisplayVariable label="Garanti leverantör:" value={elevator.guaranteeSupplier} />
            <TryDisplayVariable label="Garanti från:" value={elevator.guaranteeFrom} />
            <TryDisplayVariable label="Garanti till:" value={elevator.guaranteeTo} />
        </div>);

}

export default CustomerRentObjectBuildingElevatorComponent;