import React from "react";
import { useMsal } from "@azure/msal-react";
import "../../styles/Header.css"

export const SignOutButtonComponent = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    return (
        <button className="btn btn-secondary btn-sm btn-light login-and-out-button-color fw-bold rounded-pill" onClick={handleLogout}>Logga ut</button>
    );
};