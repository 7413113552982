import { useState } from 'react';
import { Customer } from '../models/CustomerModels';

import { RentObjectBuildingModel } from '../models/RentObjectModels';
import { CreateServiceReportComponentProperties } from '../models/ServiceReportModels';
import CreateServiceReportComponent from './CreateServiceReportComponent';

const CustomerRentObjectBuildingStaircaseComponent = ({ customer, buildingObject }: { customer: Customer, buildingObject: RentObjectBuildingModel }) => {

    const [showCompnent, setShowCompnent] = useState<Boolean>(true);

    const serviceReportRegulare: CreateServiceReportComponentProperties = {
        pm5Id: undefined,
        customnerNumber: customer.id,
        objectNumber: buildingObject.stairCaseNumber,
        contactName: `${customer.firstName} ${customer.lastName}`,
        contactPhone: customer.phoneMobile && customer.phoneMobile.length > 0 ? customer.phoneMobile : customer.phoneHome,
        contactEmail: customer.email,

        showPestDropdown: false,

        popoverLabel: 'Skapa serviceärende för traphus',
        iconClass: 'fas fa-tools',
    }


    if (!(buildingObject.stairCaseNumber)) {
        return (<div></div>)
    }
    else if (showCompnent) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(false)}>
                        <span>Trapphus</span>
                        <span className="position-absolute btn-toggle-groups">
                            <i className="fa-solid fa-caret-down"></i>
                        </span>
                    </div>
                </div>
                <div className="row ms-1">
                    <div className="row pe-0">
                        <div className="col-5 text-start">Trapphusnummer</div>
                        <div className="col-5 text-start">{buildingObject.stairCaseNumber}</div>
                        <div className="col-2 text-end color-default pe-0">
                            <CreateServiceReportComponent properties={serviceReportRegulare} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (<div>
            <div className="row">
                <div className="col-12 text-start fw-bold pt-2 position-relative btn-cursor" onClick={() => setShowCompnent(true)}>
                    <span>Trapphus</span>
                    <span className="position-absolute btn-toggle-groups">
                        <i className="fa-solid fa-caret-left"></i>
                    </span>
                </div>
            </div>
        </div>);
    }
}
export default CustomerRentObjectBuildingStaircaseComponent;