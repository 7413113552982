import { Customer } from "../models/CustomerModels";
import { RentObjectModel } from "../models/RentObjectModels";


const SearchCustomerCardRentObjectComponent = ({ rentObject }: { rentObject: RentObjectModel }) => {
    return (
        <div className="pe-5 pb-3">
            <div>
                {rentObject.rentObjectProperty.street}
            </div>
            <div className=" fst-italic">
                {rentObject.rentObjectProperty.zipCode} {rentObject.rentObjectProperty.city}
            </div>
            <div className=" fst-italic">
                {rentObject.rentObjectProperty.area} {rentObject.rentObjectProperty.district} {rentObject.rentObjectProperty.officialDistrict}
            </div>
            <div className=" fst-italic">
                {rentObject.type}
            </div>
        </div>);
}

const SearchCustomerCardRentObjectsComponent = ({ rentObjects }: { rentObjects: RentObjectModel[] }) =>
{
    console.log(rentObjects);

    if (rentObjects && rentObjects.length > 0) {
        return <>
            <div className="row pt-2">
                <label className="small">Hyresobjekt:</label>
            </div>
            <div className="d-flex flex-row flex-wrap">
                {rentObjects?.map(rentObject => {
                    return (<SearchCustomerCardRentObjectComponent rentObject={rentObject} key={rentObject.id}></SearchCustomerCardRentObjectComponent>)
                })}
            </div>
        </>
    }
    else {
        return <></>
    }
}

const SearchCustomerCardComponent = ({ customer }: { customer: Customer }) => {
    return (
        <div className="row mb-5">
            <div className="row">
                <div className={`fs-5 ${customer.isAuthenticatedToPm5 ? '' : 'color-warning'}`}>{customer.firstName} {customer.lastName} {customer.isAuthenticatedToPm5 ? <></> : <span className="fw-bold fst-italic"> : Kan inte authentisera mot underliggande system</span> }</div>
            </div>
            <div className="row">
                <div className="col-2">
                    <label className="small">Kundnummer:</label>
                    <div>{customer.id}</div>
                </div>
                <div className="col-2">
                    <label className="small">Kundtyp:</label>
                    <div>{customer.customerType}</div>
                </div>
                <div className="col-2">
                    <label className="small">Personnummer:</label>
                    <div>{customer.identificationNumber}</div>
                </div>
                <div className="col-2">
                    <label className="small">Telefon:</label>
                    <div>{customer.phoneMobile}</div>
                </div>
                <div className="col-4">
                    <label className="small">Epost:</label>
                    <div>{customer.email}</div>
                </div>
            </div>
            <SearchCustomerCardRentObjectsComponent rentObjects={customer.rentObjects}></SearchCustomerCardRentObjectsComponent>
        </div>
        );
}

export default SearchCustomerCardComponent;