import { useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Moment from 'react-moment';
import { Customer } from '../models/CustomerModels';

import { Invoice } from '../models/InvoiceModels';
import { sendInvoiceMailToCustomer } from '../services/SendMailService';

interface DisplaySettings {
    showSendInvoiceMailToCustomerSpinner: boolean;
    invoiceMailSentToCustomer: boolean;
    invoiceMailSentToCustomerException: boolean;
}


const CustomerServiceObjectReportsComponent = ({ invoice, customer }: { invoice: Invoice, customer: Customer }) => {

    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ invoiceMailSentToCustomer: false, showSendInvoiceMailToCustomerSpinner: false } as DisplaySettings);

    const ShowSpinner = () => (
        <div>
            <div className="spinner-border spinner-border-sm component-header-decoration float-end ms-2 mt-1" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            Skickar...
        </div>
    );

    const popoverMailInvoiceToCustomer = () => {
        return (
            <Popover id="popover-positioned-left">
                <h3 className="popover-header">Skicka faktura med epost</h3>

                <div className="position-absolute top-0 end-0 pt-1 pe-2">
                    <span className="fs-5" onClick={() => document.body.click()}><i className="fas fa-times"></i></span>
                </div>

                <div className="p-3">
                    <div className="mb-2">
                        Vill du skicka kundens faktura med epost?
                    </div>
                    <div className="w-100 text-end">
                        {displaySettings.showSendInvoiceMailToCustomerSpinner ? <ShowSpinner /> : null}
                        {displaySettings.invoiceMailSentToCustomerException ? <div className="text-danger mb-2">Något gick fel, testa att skicka igen.</div> : null}
                        {displaySettings.showSendInvoiceMailToCustomerSpinner || displaySettings.invoiceMailSentToCustomer ? null : <button className="btn btn-outline-primary btn-sm" onClick={() => trySendMailToResponsibles()}><i className="fas fa-envelope"></i> Skicka Mail</button>}
                        {displaySettings.invoiceMailSentToCustomer ? <div>Mailet är skickat.</div> : null}
                    </div>
                </div>
            </Popover>
        )
    };

    const OnExitMailInvoice = () => {
        setDisplaySettings((prevState) => ({ ...prevState, invoiceMailSentToCustomer: false, invoiceMailSentToCustomerException: false }))
    };

    const trySendMailToResponsibles = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showSendInvoiceMailToCustomerSpinner: true, invoiceMailSentToCustomer: false }));

        sendInvoiceMailToCustomer({
            customerNumber: customer.id,
            documentId: invoice.documentId,
            period: invoice.period,
            customerName: `${customer.firstName} ${customer.lastName}`,
        })
            .then((response) => {

                if (response) {
                    setDisplaySettings((prevState) => ({ ...prevState, invoiceMailSentToCustomer: true, invoiceMailSentToCustomerException: false }));
                }
                else {
                    setDisplaySettings((prevState) => ({ ...prevState, invoiceMailSentToCustomerException: true }));
                }
            })
            .finally(() => {
                setDisplaySettings((prevState) => ({ ...prevState, showSendInvoiceMailToCustomerSpinner: false }));
            });
    }

    return (
        <tr>
            <td className="text-start">{invoice.invoiceNumber}</td>
            <td className="text-start">{invoice.period}</td>
            <td className="text-start">{invoice.recipientNumber}</td>
            <td className="text-start">{invoice.paymentReference}</td>
            <td className="text-start">{invoice.amount}</td>
            <td className="text-start">{invoice.amountPaid}</td>
            <td className="text-start"><Moment date={invoice.dateOfPayment} format="YYYY-MM-DD" /></td>
            <td className="px-1">
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            Ladda ner faktura
                        </Tooltip>
                    }>
                    <a href={process.env.REACT_APP_BACKEND_API + '/customer/' + customer.id + '/invoices/' + invoice.documentId}><i className="fa-solid fa-file clickable-icon" data-bs-toggle="tooltip" data-bs-title="Skicka fakturan till kunden"></i></a>
                </OverlayTrigger>
                
            </td>
            <td className="px-1">
                <OverlayTrigger trigger="click" rootClose placement="left" overlay={popoverMailInvoiceToCustomer()} onExited={OnExitMailInvoice}>
                    <i className="fa-solid fa-envelope clickable-icon" data-bs-toggle="tooltip" data-bs-title="Skicka fakturan till kunden"></i>
                </OverlayTrigger>
            </td>
        </tr>
    );

}
export default CustomerServiceObjectReportsComponent;